
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
/* Heading Font - Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

/* Body Font - Open Sans */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* Display Font - Playfair Display */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

/* Inconsolata */
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');

body {
  margin: 0;
  font-family: Inconsolata, monospace !important;
  /* font-family: Roboto, sans-serif; */
  /* font-family: "Raleway", serif !important; */
  /* font-family: 'Playfair Display', serif; */
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: "Gill Sans", "Gill Sans MT", "Myriad Pro", "DejaVu Sans Condensed", Helvetica, Arial, "sans-serif"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: #faf7f1;
  color: black;
}

.fancy{
  font-family: 'Playfair Display', serif;
  font-style: italic;
}
