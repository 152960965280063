html {
  --section-background-color: #e2dcd2;
  background:#e2dcd2;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbdbdb",endColorstr="#faf7f1",GradientType=1);
  color: white;
}

a {
  color: #0000FF !important;
}

.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

.blur{
  /* color: #fdc413; */
  filter: blur(5.0px);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #faf7f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.356);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.911);
}

/* --------- */
/* Navbar Section  */
/* --------- */

.sticky {
  background-color: #1e2f3f !important;
  /* transition: all 0.3s ease-out 0s !important; */
  /* backdrop-filter: blur(15px) !important; */
}

header nav ul {
  display: flex;
  gap: 20px;
  list-style: none;
  padding: 0;
  flex-wrap: wrap; /* Allows wrapping on small screens */
  justify-content: center; /* Center items */
}

header nav ul li {
  font-weight: bold;
  text-transform: uppercase;
}

.active-page {
  color: #0000FF;
  /* font-style: italic; */
}

header a {
  color: rgb(32, 32, 32) !important;
  text-decoration: underline;
}

header a:hover {
  color: rgb(129, 129, 129) !important;
  text-decoration: none;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
  color: black;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #faf7f1 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {

  body {
    font-size: 14px!important;
  }

  main h1 {
    font-size: 20px;
  }

  header {
    padding-bottom: 50px;
  }

  header nav ul {
    /* flex-direction: column; */
    gap: 10px;
  }
    
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #304b65 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-brand {
  color: black!important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 1px;
  width: 0;
  background: #0000007a;
  bottom: 1px;
  left: 0;
  z-index: -1;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */

.home-header {
  padding-top: 80px !important;
  background-color: #faf7f1;
}

.home-section {
  position: relative;
  height: 100vh;
  background: #e2dcd2;
  z-index: 0;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  height: 90vh;
  font-size: 18px;
  /* padding: 9rem 0 2rem !important; */
  color: white;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }

  .home-content {
    font-size: 15px;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: rgb(27, 27, 27) !important;
  /* padding-top: 100px !important; */
  padding-bottom: 20px !important;
  text-align: left;
  /* font-size: 1em; */
}

/* --------- */
/* Projects */
/* --------- */

.project {
  margin-bottom: 30px;
}

.project h2 {
  font-size: 20px;
  color: rgb(10, 10, 10);
  margin-bottom: 5px;
  text-decoration: underline;
}

.project p {
  margin: 5px 0;
}

.project .year {
  font-style: italic;
  color: #555;
}

.project-list {
  list-style: none;
  text-align: left;
  padding: 0;
  padding-left: 5%;
  padding-top: 2%;
  margin: 0;
}

.project-item {
  margin-bottom: 10px;
}

.project-item a {
  color: rgb(10, 10, 10) !important;
  text-decoration: underline;
  font-size: 16px;
  display: inline-block;
}

.project-item .year {
  font-style: italic;
  color: #555;
}

.links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center; /* Center the links */
}

.link-item {
  text-decoration: underline;
  color: #007bff;
  font-weight: bold;
  transition: color 0.3s;
}

.link-item:hover {
  color: #0056b3;
}

/* Tooltip */

.tooltip-img {
  display: none;
  position: absolute;
  width: 100px; /* Set thumbnail size */
  left: 20%;
  height: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid #000000;
}

.project-item:hover .tooltip-img {
  display: block;
}

/* --------- */
/* Project Page */
/* --------- */

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-left: 5%;
}

.gallery-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
}

header img {
  height: auto;
  max-width: 100%;
  display: block;
  object-fit: cover;
  text-align: left;
  padding-left: 5%;
}

.project-details {
  text-align: justify;
  padding-left: 5%;
}

.project-details p {
  max-width: 100ch;
}

.media-wrapper {
  text-align: center;
  max-width: 600px;
  margin: auto;
}

.media-wrapper img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.media-wrapper video,
.media-wrapper audio {
  max-width: 100%;
  outline: none;
  margin-top: 8px;
}

.caption {
  margin-top: 8px;
  font-size: 14px;
  color: #555;
  text-align: center;
}

@media (max-width: 767px) {
  .project-list {
    padding: 0;
    margin: 0;
  }

  .project-details{
    padding-left: 0%;
  }

  .gallery{
    padding-left: 0%;
  }

  
  header img {
    padding-left: 0%;
  }
}

/* --------- */
/* About */
/* --------- */

.about-section {
  background-color: #faf7f1;
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: black !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}
